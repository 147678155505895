import React from "react";
import {
  StyledComponent,
  StyledModal,
} from "../../common/materials/GenericStyleComponent";
import { Box, Button, Grid, Typography } from "@mui/material";
import Colors from "../../common/materials/colors";
import styled from "styled-components";
import ButtonStyled from "../../common/elements/Button";

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 350px;
  text-align: center;
  padding: 1.5em;
  border-radius: 10px;
  border-color: rgb(217, 43, 60);
  border-width: 1px;
  border-style: solid;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 400px;
  }
`;
const StyledIcon = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

function DeleteAccountConfirmationPopUp({
  submit,
  cancel,
}: {
  submit: () => void;
  cancel: () => void;
}) {
  return (
    <StyledModal open={true}>
      <StyledComponent>
        <StyledWrapper>
          <StyledIcon
            src="/images/deleteAccount/warningZipang.png"
            alt="zipang logo #1"
          />
          <Typography>
            Are you sure you want to delete your account? This will erase all
            your information from our database permanently, and the process
            cannot be reversed. It may take up to 30 days for all your data to
            be completely removed.
          </Typography>

          <Box
            style={{
              gap: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonStyled
              isMobile={false}
              onClick={submit}
              type="contained-red"
            >
              Yes
            </ButtonStyled>
            <Button
              onClick={cancel}
              sx={{
                color: "black",
                borderColor: "rgb(217, 43, 60)",
                "&:hover": {
                  backgroundColor: "rgb(217, 43, 60)",
                  borderColor: "white",
                },
                "&:focus": {
                  outline: "none",
                  boxShadow: "none",
                },
              }}
              variant="outlined"
            >
              No
            </Button>
          </Box>
        </StyledWrapper>
      </StyledComponent>
    </StyledModal>
  );
}

export default DeleteAccountConfirmationPopUp;
