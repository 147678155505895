import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Grid, makeStyles, TextField } from "@material-ui/core";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Colors from "../../common/materials/colors";
import { StyledTextFieldWrapper } from "../LandingPage/Header/StyledComponent";
import Button from "../../common/elements/Button";
import Footer from "../zipang/Footer";
import DeleteAccountConfirmationPopUp from "./DeleteAccountConfirmationPopUp";
import DeleteAccountThankYouPopup from "./DeleteAccountThankYouPopup";
import {
  deleteUserAccount,
  sendLoginOTP,
  tokenUrl,
} from "../../common/config/app.endpoints";
import AuthService from "../../components/auth/AuthService";
import { useHistory } from "react-router";

const StyledComponents = styled.div`
  height: 90vh;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-containe: space-between;
`;

const StyledLogo = styled.img`
  width: 120px;
  height: 40px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 100px;
    display: flex;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 160px;
  }
`;
const StyledDiamondImg = styled.img`
  width: 500px;
  height: 400px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 310px;
    height: 200px;
    object-fit: contain;
    display: flex;
  }
`;
const StyledBoxcontainer = styled(Box)`
  padding: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10%;
  }
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 12px;
  margin: 10px 0px;
  margin-left: 1.2em;
`;

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: Colors.red,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    background: Colors.white,
  },
  margin: {
    margin: theme.spacing(1),
  },
  signInButton: {
    width: "44px",
    height: "44px",
    position: "relative",
  },
}));

const StyledotpTextContainer = styled.span`
  background: ${(props) => props.theme.colors.base.transparent};
  color: #4a95f0;
  font-family: "Calibre-Regular";
  font-size: 1em;
  cursor: pointer;
`;
const stateList = {
  submitEmail: "submitEmail",
  submitOtp: "submitOtp",
  confirm: "confirm",
  thankyou: "thankyou",
};

const getRemaining = (time: any) => {
  const mins: any = Math.floor(time / 60);
  const secs: any = time - mins * 60;
  const formatNumber = (number: any) => `0${number}`.slice(-2);
  return { mins: formatNumber(mins), secs: formatNumber(secs) };
};

const DeleteAccount = (props: any) => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [reaminingSecs, setReaminingSecs] = useState(30);
  const [isResend, setIsResend] = useState(false);
  const [state, setState] = useState<String>(stateList.submitEmail);
  let history = useHistory();
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState<string | undefined>("");
  const { mins, secs } = getRemaining(reaminingSecs);

  const Auth = new AuthService();
  const classes = useStyles();
  let { t } = useTranslation();

  useEffect(() => {
    let interval: any = null;

    interval = setInterval(() => {
      setReaminingSecs((reaminingSecs) => reaminingSecs - 1);
    }, 1000);

    if (reaminingSecs === 0) {
      setIsResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [reaminingSecs]);

  const sendOtp = () => {
    const checkEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}/g
    ).test(email.trim());
    if (checkEmail !== true) {
      setMessage("Please enter correct email address.");
      return;
    }
    Auth.postRequestWithoutAuthorization(
      `${sendLoginOTP}?un=${email}`,
      "data",
      (status: number, response: any) => {
        if (status === 200) {
          setIsResend(false);
          setReaminingSecs(30);
          setState(stateList.submitOtp);
        } else if (status > 200 && status < 500) {
          if (response[0].code !== undefined) {
            if (response[0].code === "KB412004") {
              setMessage(`Please enter correct email address.`);
            } else if (response[0].code === "KB404001") {
              setMessage(`Not a registered email Id`);
            } else {
              setMessage(`Invalid request.`);
            }
          }
        } else {
        }
      }
    );
  };

  const handleDeleteAccount = () => {
    if (token !== undefined) {
      Auth.deleteAccountRequest(
        deleteUserAccount + `?email=${email}&deviceType=web`,
        token,
        (status: number, response: any) => {
          if (status === 200) {
            setState(stateList.thankyou);
            Auth.logout();
          } else {
            setMessage(response?.data?.error ?? "");
            setState(stateList.submitOtp);
          }
        }
      );
    }
  };

  const checkOtpValidations = () => {
    let digits = /^[0-9]+$/;
    if (email === "" && otp === "") {
      setMessage(`Please fill required fields`);
    } else if (email === "") {
      setMessage(`Please enter correct email address.`);
    } else if (!otp.match(digits)) {
      setMessage(`Please enter correct OTP`);
    } else {
      const data = {
        username: email,
        password: otp,
        type: "OTP",
      };
      Auth.postRequestWithoutAuthorization(
        tokenUrl,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            setState(stateList.confirm);
            setToken(response.token);
          } else if (status > 200 && status < 500) {
            if (response[0].code !== undefined) {
              if (response[0].code === "KB412003") {
                setMessage(`The code you entered is incorrect.`);
              } else if (response[0].code === "KB409001") {
                setMessage(
                  `Your profile is still under verification. We will send you an email on your registered email address once your profiles is verified`
                );
              } else if (response[0].code === "KB412004") {
                setMessage(`Email or Password is not recognized or incorrect.`);
              } else if (response[0].code === "KB401002") {
                setMessage(`This ID is registered as Auctioneer only`);
              } else if (response[0].code === "KB404001") {
                setMessage(`User not registered`);
              } else if (response[0].code === "KB401004") {
                setMessage(``);
              } else if (response[0].code === "KB401005") {
                setMessage(`You don't have any invitation to access.`);
              } else {
                setMessage(`Invalid request.`);
              }
            }
          }
        }
      );
    }
  };

  const handleSubmit = () => {
    switch (state) {
      case stateList.submitEmail:
        if (email === "") {
          setMessage("Please enter email address.");
        } else {
          sendOtp();
        }
        break;
      case stateList.submitOtp:
        checkOtpValidations();
        break;
      default:
    }
  };

  return (
    <StyledComponents>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: {
              sm: "none",
              md: "flex",
              justifyContent: "left",
              marginLeft: "10px",
              cursor: "pointer",
            }, // Hidden on extra-small screens, visible on small+
          }}
        >
          <StyledLogo
            src="/images/deleteAccount/zipangLogo.png"
            alt="zipang logo #1"
          />
        </Box>
      </div>
      <Grid container>
        <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
          <StyledBoxcontainer>
            <span style={{ fontSize: "22px", fontWeight: "bold" }}>
              Delete Account
            </span>
            <span style={{ fontSize: "16px", fontWeight: "lighter" }}>
              We're sorry to see you go.
            </span>

            <StyledMessage>{message}</StyledMessage>
            {state === stateList.submitEmail && (
              <StyledTextFieldWrapper
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <TextField
                  InputProps={{ className: classes.input }}
                  type="email"
                  label={"Email"}
                  variant="outlined"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setMessage("");
                  }}
                  autoComplete="off"
                />
              </StyledTextFieldWrapper>
            )}
            {!(state === stateList.submitEmail) && (
              <StyledTextFieldWrapper
                fullWidth
                className={classes.margin}
                variant="outlined"
              >
                <TextField
                  InputProps={{ className: classes.input }}
                  label={t("common:profile:forgotPassword:labels:otp")}
                  type="text"
                  required
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setMessage("");
                  }}
                  variant="outlined"
                  id="new-password"
                  autoComplete="off"
                />
              </StyledTextFieldWrapper>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                isMobile={false}
                onClick={handleSubmit}
                wide
                widePadding
                type="contained-red"
              >
                {state === stateList.submitEmail ? "Send OTP" : "Continue"}
              </Button>
              {state === stateList.submitOtp && (
                <span
                  // onClick={}
                  style={{
                    fontSize: "16px",
                    fontWeight: "lighter",
                    cursor: "pointer",
                  }}
                >
                  {isResend ? (
                    <StyledotpTextContainer
                      onClick={() => {
                        sendOtp();
                        setOtp("");
                      }}
                      style={{
                        textDecoration: "underline",
                        textAlign: "end",
                        color: "rgb(217, 43, 60)",
                        textDecorationLine: "underline",
                      }}
                    >
                      Resend OTP
                    </StyledotpTextContainer>
                  ) : (
                    <StyledotpTextContainer
                      style={{
                        textDecoration: "underline",
                        textAlign: "end",
                        color: "rgb(217, 43, 60)",
                        textDecorationLine: "underline",
                      }}
                    >
                      Resend in: {`${secs}`} seconds
                    </StyledotpTextContainer>
                  )}
                </span>
              )}
            </div>
          </StyledBoxcontainer>
        </Grid>
        <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
          <StyledBoxcontainer>
            <StyledDiamondImg
              src="/images/deleteAccount/diamondImg_dlt.png"
              alt="zipang image #1"
            />
          </StyledBoxcontainer>
        </Grid>
      </Grid>
      {state === stateList.confirm && (
        <DeleteAccountConfirmationPopUp
          submit={handleDeleteAccount}
          cancel={() => history.push("/zipang")}
        />
      )}
      {state === stateList.thankyou && <DeleteAccountThankYouPopup />}

      <Footer />
    </StyledComponents>
  );
};

export default DeleteAccount;
