import React from "react";
import {
  StyledComponent,
  StyledModal,
} from "../../common/materials/GenericStyleComponent";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import Button from "../../common/elements/Button";
import { useHistory } from "react-router";

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 350px;
  text-align: center;
  padding: 1.5em;
  border-radius: 10px;
  border-color: rgb(217, 43, 60);
  border-width: 1px;
  border-style: solid;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 400px;
  }
`;

const StyledIcon = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

function DeleteAccountThankYouPopup() {
  let history = useHistory();

  return (
    <StyledModal open={true}>
      <StyledComponent>
        <StyledWrapper>
          <StyledIcon
            src="/images/deleteAccount/correctZipang.png"
            alt="zipang logo #1"
          />
          <Typography>
            We're sorry to see you go! Your account deletion request has been
            successfully submitted.
          </Typography>

          <Button
            isMobile={false}
            onClick={() => {
              history.push("/zipang");
            }}
            type="contained-red"
          >
            OKAY
          </Button>
        </StyledWrapper>
      </StyledComponent>
    </StyledModal>
  );
}

export default DeleteAccountThankYouPopup;
